import { createSlice } from "@reduxjs/toolkit";

const initDashBoardState = {
  statistic: {},
  plantIdStats: {},
  listLoading: false,
  actionsLoading: false,
  error: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const dashBoardSlice = createSlice({
  name: "dashBoard",
  initialState: initDashBoardState,
  reducers: {
    catchError: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    statisticOverviewFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.statistic = data.data || {};
    },

    statisticPlantIdFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.plantIdStats = data.data || {};
    },
  },
});
