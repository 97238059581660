import { TooltipBar } from "app/common/helper/TooltipBar";
import { NavLink, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../common/helper/RouterHelper";
import { asideMenus } from './aside-menu/asideMenuData'
import { useSelector } from 'react-redux'

function Aside({ showAsideMenu }) {
  const location = useLocation();
  const profile = useSelector((state => state.auth.profile));
  const asideMenusArray = asideMenus(profile);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  const getWidthAsideMenu = () => {
    if (showAsideMenu) {
      return "300px";
    } else {
      return "100px";
    }
  };

  return (
    <div
      className={`aside aside-wrapper d-flex flex-column justify-content-between`}
      style={{ width: getWidthAsideMenu() }}
    >
      <ul className="aside-menu p-0">
        {asideMenusArray.map((asideMenuItem, index) => (
          <li
            className={`${
              getMenuItemActive(asideMenuItem.path) ? "menu-item-active" : " "
            } aside-menu-item`}
            key={index}
          >
            {!showAsideMenu ? (
              <>
                <TooltipBar title={asideMenuItem.label} placement="right">
                  <NavLink
                    to={asideMenuItem.path}
                    className={`w-100 h-100 d-flex align-items-center justify-content-center`}
                  >
                    <img
                      src={
                        getMenuItemActive(asideMenuItem.path)
                          ? asideMenuItem.iconActive
                          : asideMenuItem.icon
                      }
                      alt="menu"
                    />
                  </NavLink>
                </TooltipBar>
              </>
            ) : (
              <>
                <NavLink
                  to={asideMenuItem.path}
                  className={`w-100 h-100 d-flex align-items-center `}
                >
                  <img
                    src={
                      getMenuItemActive(asideMenuItem.path)
                        ? asideMenuItem.iconActive
                        : asideMenuItem.icon
                    }
                    alt="menu"
                    className="mr-4"
                  />
                  <span className="">{asideMenuItem.label}</span>
                </NavLink>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Aside;
