import { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { asideMenus} from '../layout/components/aside/aside-menu/asideMenuData'
import SplashScreen from "./components/SplashScreen";
import { useSelector } from 'react-redux'

function RootRoutes() {
  const profile = useSelector((state => state.auth.profile));
  const asideMenusArray = asideMenus(profile);
  return (
    <Suspense fallback={<SplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
         profile?.roles && profile?.roles.includes('editor') ?
         <Redirect exact from="/" to="/need-plant-care" />
         : <Redirect exact from="/" to="/dashboard" />
        }

        {asideMenusArray.map((asideItem, index) => (
          <Route path={asideItem.path} component={asideItem.component} key={index} />
        ))}

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}

export default RootRoutes;
